import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Brain Rank IQ Test - Start Your IQ Test
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Section overflow-x="hidden" overflow-y="hidden" quarkly-title="Hero-6">
			<Override slot="SectionContent" flex="1 1 0%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				position="static"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					position="static"
					lg-width="100%"
					sm-padding="0 30px 80px 30px"
					padding="0px 20px 0px 20px"
				>
					<Box display="flex" justify-content="center">
						<Box
							border-radius="0 0 0 15px"
							width="fit-content"
							align-items="center"
							justify-content="flex-start"
							opacity="0.6"
							right={0}
							padding="0px 3px 0px 3px"
							position="absolute"
							background="--color-secondary"
							border-color="--color-lightD2"
							border-style="solid"
							border-width="1px"
							top={0}
						>
							<Text
								margin="0px 0px 0px 0px"
								text-align="left"
								font="--base"
								padding="2px 2px 2px 2px"
								color="--light"
								border-color="--color-primary"
								border-radius="0 15px 15px 0"
							>
								IQR certified
							</Text>
						</Box>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text
							margin="0px 0px 20px 0px"
							font="--headline1"
							sm-font="normal 700 30px/1.2 --fontFamily-sans"
							color="--primary"
							as="h1"
						>
							Discover Your Cognitive Brilliance with Brain Rank IQ Test
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
						margin="50px 0px 50px 0px"
					>
						<LinkBox
							margin="0px 15px 0px 0px"
							padding="20px 35px 20px 5px"
							border-radius="50px 5px 5px 50px"
							font="normal 600 30px/1.5 --fontFamily-googlePoppins"
							text-decoration="none"
							sm-margin="0px 0 15px 0px"
							hover-color="--vividOrange"
							transition="background-color 0.5s --transitionTimingFunction-easeInOut 0s"
							color="--light"
							href="/iq-test"
							target="_self"
							hover-border-color="--color-vividOrange"
							hover-border-width="2px"
							hover-border-style="solid"
							border-width="2px"
							border-style="solid"
							background="--color-vividOrange"
							text-align="center"
							letter-spacing="1.5px"
							hover-font="normal 600 30px/1.5 --fontFamily-googlePoppins"
							display="flex"
							flex-direction="row"
							justify-content="space-around"
							grid-template-columns="repeat(2, 1fr)"
							max-width="456px"
							hover-background="--color-vividOrange linear-gradient(-90deg,rgba(0,0,0,0) 0%,--color-vividOrange 100%),--color-light"
						>
							<Image
								src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17:52:58.938Z"
								display="block"
								position="relative"
								width="64px"
								margin="0px 35px 0px 0px"
								background="--color-vividOrange"
								border-radius="50%"
								sm-margin="0px 2px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/iqr_seal_white.webp?v=2024-02-27T17%3A52%3A58.938Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
							<Text as="h2" font="normal 600 36px/1.2 --fontFamily-googlePoppins">
								Get Started
							</Text>
						</LinkBox>
					</Box>
					<Text margin="0px 0px 25px 0px" font="--lead" color="--primary">
						Welcome to our Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. In this test, you'll encounter a variety of questions designed to challenge different cognitive aspects, logical reasoning, and pattern recognition.
					</Text>
					<Text margin="0px 0px 25px 0px" font="--lead" color="--primary">
						Be mindful of the 20-minute time limit, which tests your quick-thinking abilities. Your support in taking this test, priced from £12.50, not only reveals your cognitive potential but also helps us build a future learning platform focused on essential life skills. Take a deep breath, prepare to think on your feet, and begin your intellectual adventure!
					</Text>
					<Text margin="0px 0px 25px 0px" font="--lead" color="--primary">
						For mobile device users, please remember to scroll down to view all options for each question.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="758px"
					display="flex"
					margin="0px -50px 0px 0px"
					position="static"
					bottom="-298px"
					height="716px"
					left="614px"
					right="-52px"
					top="-28px"
					flex="1 1 0%"
					lg-margin="0px 0 0px 0px"
					lg-align-self="center"
					lg-padding="0px 0px 80px 0px"
					sm-flex="1 1 0%"
					sm-align-self="auto"
					sm-width="100%"
					sm-padding="0px 0px 40px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						margin="80px 0 0 25px"
						width="33%"
						lg-margin="80px 0 0 0"
						sm-margin="50px 0 0 0"
					>
						<Image
							src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21:11:09.789Z"
							display="block"
							height="300px"
							border-radius="25px"
							object-fit="cover"
							width="100%"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/neural_networks.jpg?v=2024-02-03T21%3A11%3A09.789Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						margin="80px 0 0 25px"
						width="33%"
						sm-align-self="center"
						sm-margin="50px 0 0 15px"
					>
						<Image
							src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21:11:09.770Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/einstein_wallpaint.jpg?v=2024-02-03T21%3A11%3A09.770Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Image
							src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21:11:09.765Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/cubes.jpg?v=2024-02-03T21%3A11%3A09.765Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						margin="0 0 0 25px"
						width="33%"
						justify-content="flex-start"
						sm-margin="0 0 0 15px"
					>
						<Image
							src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21:11:09.759Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/gears.jpg?v=2024-02-03T21%3A11%3A09.759Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Image
							src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21:11:09.767Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/fresco.jpg?v=2024-02-03T21%3A11%3A09.767Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.ChartPreview>
			<Override slot="ChartTitle" display="none" />
			<Text
				margin="0px 0px 0px 0px"
				font="--headline2"
				color="--primary"
				text-align="center"
				as="h2"
			>
				Discover Your Position on the IQ Spectrum
			</Text>
		</Components.ChartPreview>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="box-right" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link
				href={"https://iqresearches.com/"}
				rel={"canonical"}
				sizes={""}
				place={"endOfHead"}
				rawKey={"661e637b1dd8748446cd1108"}
			/>
		</RawHtml>
	</Theme>;
});